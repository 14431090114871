import React, { useEffect, useReducer, useState } from "react";
import { Dialog,FormLabel, Button, Paper, DialogTitle, Divider, DialogContent, Container, TextField, Select, InputLabel, DialogActions, Typography, IconButton, } from '@material-ui/core'
import api from "../../services/api"
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { AttachFile, Delete } from "@material-ui/icons";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flow-root",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
    ContentContainer: {
        margin: `4px`,
        marginTop: `8px`
    },
    wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex'
    },
    paper: { minWidth: "500px" },
}));

const AddCampanha = ({ open, onClose }) => {

    const classes = useStyles();
    const [whatsData, setWhatsData] = useState([])
    const [filasData, setFilasData] = useState([])
    const [filaLocked, setFilaLocked] = useState(true)
    const [fila, setFila] = useState(null)
    const [whats, setWhats] = useState('')
    const [campanha, setCampanha] = useState('')
    const [tempoInicio, setTempoInicio] = useState('08:00')
    const [tempoFinal, setTempoFinal] = useState('23:59')
    const [dataInicio, setDataInicio] = useState('')
    const [dataFinal, setDataFinal] = useState('')
    const [msg,setMensagem] = useState(``)
    const [imagem,setImagem] = useState(``)
    
    const onSelectFile = e => {
        setImagem(e.target.files[0])
    }

    const handleClose = () => {
        //        setReload(!reload)
                onClose();
            };

    useEffect(() => {
        const getData = async () => {
            const { data } = await api.get("/whatsapp");
            setWhatsData(data)
        }
        getData()
    }, [])

    const handleWhats = (e) => {
        if (e.target.value == 0) {
            setFilasData([])
            setFilaLocked(true)
        }
        whatsData.forEach(i => {
            if (i.id == e.target.value) {
                setFilasData(i.queues)
                setFilaLocked(false)
            }
            setWhats(e.target.value)
        });
    }

    const handleFila = (e) => {
        setFila(e.target.value)
    }

    const handleChangeInput = (e) => {
        setCampanha(e.target.value)
    }

    const handleSubmit = async () => {

        if(!whats  || !campanha || !tempoInicio || !tempoFinal || !dataInicio){
            toast.error(`Por favor preencha os  requeridos.`)
            return 0
        }



        var data = new FormData()
        data.append(`whatsappId`,whats)
        data.append(`queueId`,fila)
        data.append(`campanha`,campanha)
        data.append(`horarioInicial`,tempoInicio)
        data.append(`horarioFinal`,tempoFinal)
        data.append(`dataInicial`,dataInicio)
        data.append(`dataFinal`,dataFinal)
        data.append(`mensagem`,msg)
        if (!imagem || imagem == undefined) {
            data.append("imagem", 0)
        } else {
            data.append("imagem", imagem)
        }
        let json = {
            whatsappId: whats,
            queueId: fila,
            campanha: campanha,
            horarioInicial: tempoInicio,
            horarioFinal: tempoFinal,
            dataInicial: dataInicio,
            dataFinal: dataFinal,
            mensagem: msg,
        }
        let res = await api.post('/addCampanha',data)
        if(res.status == 200){
            toast.success(`Campanha adicionada com sucesso!`)
        }
        if(res.status == 400){
            toast.error(`Erro ao adicionar a campanha!`)
        }
        if(res.status == 406){
            toast.error(`Já existe uma campanha com este nome!`)
        }
        handleClose()
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{paper: classes.paper}}
        >
            <DialogTitle>
                Adicionar Campanha
            </DialogTitle>
            <DialogContent dividers='true'>
                <Container>
                    <Container style={{ margin: '4px' }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            // className={classes.txtMensagem}
                            fullWidth
                            id="nome"
                            value={campanha}
                            onChange={handleChangeInput}
                            label="Digite o nome da campanha..."
                            name="Nome"
                            autoComplete="Campanha"
                            autoFocus
                            className={classes.textField}
                        />
                    </Container>
                    <Container className={classes.ContentContainer}>
                        <InputLabel style={{ display: 'flex', margin: '4px' }}>Conexão</InputLabel>
                        <Select
                            autoWidth
                            native
                            label="Conexão Whatsapp"
                            style={{ display: 'flex', margin: '2px' }}
                            displayEmpty
                            required
                            defaultValue={whats}
                            value={whats}
                            onChange={e => handleWhats(e)}
                        >
                            <option value={0}> </option>
                            <>{whatsData.map(w => (
                                <option value={w.id}>{w.name}</option>
                            ))}</>
                        </Select>
                    </Container>
                    <Container className={classes.ContentContainer}>
                        <InputLabel style={{ display: 'flex', margin: '4px' }}>Fila</InputLabel>
                        <Select
                            autoWidth
                            native
                            required
                            label="Fila"
                            style={{ display: 'flex', margin: '2px' }}
                            disabled={filaLocked}
                            defaultValue={fila}
                            value={fila}
                            onChange={e => handleFila(e)}
                        >
                            <option value={0}>Sem Fila</option>
                            <>{filasData.map(f => (
                                <option value={f.id}>{f.name}</option>
                            ))}</>
                        </Select>
                    </Container>
                    <Container className={classes.ContentContainer}>
                    <FormLabel  style={{ display: 'flex', margin: '4px' }}>Horário</FormLabel >
                    <TextField
                            id="time"
                            label="Inicio"
                            type="time"
                            defaultValue="08:00"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                            style={{width: 80, padding: `4px`, marginRight: `35%`}}
                            onChange={e => setTempoInicio(e.target.value)}
                            dvalue={tempoInicio}
                            required
                        />
                        <TextField
                            id="time"
                            label="Fim"
                            type="time"
                            defaultValue="23:59"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                            style={{width: 80, padding: `4px`}}
                            onChange={e => setTempoFinal(e.target.value)}
                            value={tempoFinal}
                            required
                        />
                    </Container>
                    <Container className={classes.ContentContainer}>
                    <FormLabel  style={{ display: 'flex', margin: '4px' }}>Data</FormLabel >
                    <TextField
                            id="time"
                            label="Inicio"
                            type="date"
                            defaultValue="08:00"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                            style={{width: 150, padding: `4px`, marginRight: `10%`}}
                            onChange={e => setDataInicio(e.target.value)}
                            value={dataInicio}
                            required
                        />
                        <TextField
                            id="time"
                            label="Fim"
                            type="date"
                            defaultValue="23:59"
                            value={dataFinal}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                            style={{width: 150, padding: `4px`}}
                            onChange={e => setDataFinal(e.target.value)}
                            required
                        />
                    </Container>
                    <Container className={classes.ContentContainer}>
                    <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            className={classes.txtMensagem}
                            fullWidth
                            multiline
                            minRows={3}
                            id="msg"
                            value={msg}
                            onChange={e => setMensagem(e.target.value)}
                            label="Digite sua mensagem..."
                            name="msg"
                            autoComplete="mensagem"
                            autoFocus
                        ></TextField>
                    </Container>
                    <Container className={classes.ContentContainer}>
                    <input style={{ display: 'none' }} id='inputFile' type="file" accept="image/*" onChange={onSelectFile} />
                        <label htmlFor="inputFile">
                            <Button
                                variant="contained"
                                className={classes.btnUpload}
                                color="primary"
                                component="span"
                            >
                                Upload do anexo
                            </Button>
                        </label>
                    </Container>
                    <Container>
                    <Typography variant="subititle1" className={classes.wrapIcon}>
                    <AttachFile /> {imagem.name}
                    <IconButton
                                            size='small'
                                            onClick={() => {setImagem(``)}}
                                        >
                                            <Delete></Delete>
                        </IconButton>
                    </Typography>
                </Container>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="secondary"
                    //disabled={isSubmitting}
                    variant="outlined"
                >
                    Sair
                </Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    //disabled={isSubmitting}
                    variant="contained"
                >
                    Cadastrar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddCampanha